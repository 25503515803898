import { observable, flow, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export class StrategicControlBrowseStore {
    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public controls: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public query: any = {};
    @observable public error: any;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Control Registry",
            key: "controls-dashboard",
            onClick: () => this.parentStore.rootStore.routing.push(`/controls/dashboard`),
        });

        builder.push({ text: "Browse Strategic Controls", key: "browse-strategic", isCurrentItem: true });

        return builder;
    }

    public loadControls = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: {} }, options);

        this.loading = true;
        this.controls = [];
        this.query = o.query;
        this.error = null;

        try {
            const result = yield this.controlService.searchControls({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: { ...o.query, level: [1], activeOnly: true },
            });

            this.controls = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            //this.query = result.query;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
