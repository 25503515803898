import { observable, action } from "mobx";

import { RiskService } from '../../../api/risks';
import { RiskStore } from "./RiskStore";

export class RiskAssessmentStore {

    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public assessment: any;
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public loadAssessment(id) {
        this.loading = true;
        this.assessment = null;
        return this.riskService.getRiskAssessment(id)
            .then(this.loadAssessmentOnSuccess, this.loadAssessmentOnError);
    }

    @action.bound
    public loadAssessmentOnSuccess(result) {
        this.assessment = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadAssessmentOnError(error) {
        this.error = error;
        this.loading = false;
        throw error;
    }
}
