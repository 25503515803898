function getTenantInfo(hostname) {
    if (window.cygboot && window.cygboot.bootstrap) {
        console.log("INFO: bootstrapping from config");
        return {
            tenantId: window.cygboot.tenantId,
            clientId: window.cygboot.clientId,
            authority: window.cygboot.authority,
            scope: window.cygboot.scope,
            name: window.cygboot.name,
            logoUrl: window.cygboot.logoUrl,
            modules: {
                risks: {
                    enabled: window.cygboot.tenantId !== "bd5c6713-7399-4b31-be79-78f2d078e543",
                    maxImpact: 4,
                    maxLikelihood: 4,
                    invertAxis: false,
                    score: (cell) => {
                        const score = cell.impact * cell.likelihood;

                        if (score < 4) {
                            return "Minor";
                        }

                        if (score < 12) {
                            return "Moderate";
                        }

                        if (score < 16) {
                            return "Major";
                        }

                        return "Critical";
                    },
                },
            },
        };
    }

    switch (hostname) {
        case "cygraph-int-app.azurewebsites.net":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
                name: "Zerodai",
                logoUrl: "/images/logos/zerodai.png",
            };
        case "demo1.zerodayapp.com":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
                name: "Zerodai",
                logoUrl: "/images/logos/zerodai.png",
            };
        case "mnscorp.zerod.ai":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
                name: "M&S InfoSec Hub",
                logoUrl: "/images/logos/mns.png",
            };
        case "dominos.zerod.ai":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
                name: "Domino's",
                logoUrl: "/images/logos/dominos.png",
                modules: {
                    risks: {
                        enabled: true,
                        maxImpact: 5,
                        maxLikelihood: 5,
                        invertAxis: false,
                        score: (cell) => {
                            const score = cell.impact * cell.likelihood;

                            if (score <= 5) {
                                return "Minor";
                            }

                            if (score <= 10) {
                                return "Moderate";
                            }

                            if (score <= 15) {
                                return "Major";
                            }

                            return "Critical";
                        },
                    },
                },
            };
        case "metrobank.zerod.ai":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
                name: "Metro Bank",
                logoUrl: "/images/logos/metro-bank.png",
            };
        case "demo1.zerod.ai":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
                name: "Zerodai",
                logoUrl: "/images/logos/zerodai.png",
                modules: {
                    controls: {
                        enabled: true,
                    },
                    risks: {
                        enabled: true,
                        maxImpact: 5,
                        maxLikelihood: 5,
                        invertAxis: false,
                        score: (cell) => {
                            const score = cell.impact * cell.likelihood;

                            if (score <= 5) {
                                return "Minor";
                            }

                            if (score <= 10) {
                                return "Moderate";
                            }

                            if (score <= 15) {
                                return "Major";
                            }

                            return "Critical";
                        },
                    },
                },
            };
        case "cyb-eun-nprd-cygraph-app1.azurewebsites.net":
            return {
                tenantId: "bd5c6713-7399-4b31-be79-78f2d078e543",
                clientId: "d200a7dc-106f-48b8-9cfa-a7097d2378d5",
                authority: "https://login.microsoftonline.com/bd5c6713-7399-4b31-be79-78f2d078e543",
                scope: "e87365f1-d141-4433-9dba-10c6914ca2fa/user_impersonation",
                name: "M&S InfoSec Hub",
                logoUrl: "/images/logos/mns.png",
            };
        case "cyb-eun-prd-cygraph-app1.azurewebsites.net":
            return {
                tenantId: "bd5c6713-7399-4b31-be79-78f2d078e543",
                clientId: "9d4b6d0f-0fd6-4166-81a4-4a2076fc51c2",
                authority: "https://login.microsoftonline.com/bd5c6713-7399-4b31-be79-78f2d078e543",
                scope: "7805319d-b918-4b52-9304-c82d4be48396/user_impersonation",
                name: "M&S InfoSec Hub",
                logoUrl: "/images/logos/mns.png",
            };
        case "app-cyg-uks-ih01.azurewebsites.net":
            return {
                tenantId: "dominos.co.uk",
                clientId: "a0933607-77af-4e15-a378-3e2760b9c6b5",
                authority: "https://login.microsoftonline.com/dominos.co.uk",
                scope: "3b7d47ba-b43b-4c00-a7b0-492aadd813b5/user_impersonation",
                name: "Domino's",
                logoUrl: "/images/logos/dominos.png",
            };
        default:
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
                name: "Zerodai InfoSec Hub",
                logoUrl: "/images/logos/zerodai.png",
                modules: {
                    controls: {
                        enabled: true,
                    },
                    risks: {
                        enabled: true,
                        maxImpact: 4,
                        maxLikelihood: 4,
                        invertAxis: false,
                        score: (cell) => {
                            const score = cell.impact * cell.likelihood;

                            if (score < 4) {
                                return "Minor";
                            }

                            if (score < 12) {
                                return "Moderate";
                            }

                            if (score < 16) {
                                return "Major";
                            }

                            return "Critical";
                        },
                    },
                },
            };
    }
}

export const tenantInfo = getTenantInfo(window.location.hostname);

export const ModuleNames = {
    Risks: "risks",
    Assessments: "assessments",
    Controls: "controls",
};

export const getModuleConfig = (name) => {
    if (tenantInfo && tenantInfo.modules && tenantInfo.modules[name]) {
        return tenantInfo.modules[name];
    }

    return {};
};

export const isModuleEnabled = (name) => {
    return tenantInfo && tenantInfo.modules && tenantInfo.modules[name] && tenantInfo.modules[name].enabled;
};

export const getModuleProperty = (name, prop, def) => {
    return tenantInfo &&
        tenantInfo.modules &&
        tenantInfo.modules[name] &&
        tenantInfo.modules[name][prop] !== "undefined"
        ? tenantInfo.modules[name][prop]
        : def;
};
