export const WorkflowConstants = {
    entityTypes: [
        { id: "trigger", name: "Trigger" },
        { id: "module", name: "Module" },
        { id: "principal", name: "Users & Groups" },
        { id: "assessment", name: "Assessment" },
        { id: "work-item", name: "Demand" },
        { id: "triage", name: "Triage" },
        { id: "risk", name: "Risk" },
        { id: "risk-registry", name: "Risk Registry" },
        { id: "risk-type", name: "Risk Type" },
        { id: "asset", name: "Inventory" },
        { id: "asset-type", name: "Inventory Type" },
        { id: "portfolio", name: "Portfolio" },
        { id: "product", name: "Product" },
    ],
    scopes: [
        {
            id: "work-item",
            enabled: true,
            name: "Demand",
            group: "work-items",
            color: "var(--green-2)",
            module: "ef2eefe7-82b7-490c-a711-1b3807d27d7c",
        },
        {
            id: "assessment",
            enabled: true,
            name: "Assessment",
            group: "assessments",
            color: "var(--teal-2)",
            module: "5cd3204f-dcad-41d4-9519-d9398f744d67",
        },
        {
            id: "risk",
            enabled: true,
            name: "Risk",
            group: "risks",
            color: "var(--pink-2)",
            module: "4fe548f0-f670-4c66-817f-34c5fbcb5a82",
        },
        {
            id: "inventory",
            enabled: true,
            name: "Inventory",
            group: "inventory",
            color: "var(--violet-2)",
            module: "be26cb4e-0377-4cdd-9310-262d060bfccf",
        },
    ],
    activities: [
        {
            id: "zerodai:activities:built-in:send-email",
            name: "Send an email",
            icon: "Mail",
        },
        {
            id: "zerodai:activities:built-in:create-task",
            name: "Create a task",
            icon: "EventToDoLogo",
        },
        {
            id: "zerodai:activities:built-in:log-output",
            name: "Log output",
            icon: "EditNote",
        },
        {
            id: "zerodai:activities:built-in:http-request",
            name: "Make a HTTP request",
            icon: "Globe",
        },
    ],
    triggers: [
        {
            id: "event",
            name: "Event triggers",
            icon: "LightningBolt",
        },
        {
            id: "workflow",
            name: "Workflow triggers",
            icon: "VisioDiagram",
        },
        {
            id: "property",
            name: "Property updates",
            icon: "Edit",
        },
    ],
    events: [
        {
            id: "work-items.automation.action",
            name: "When a demand automation is run",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.stateflow",
            name: "When a demand workflow is triggered",
            trigger: "workflow",
            group: "work-items",
        },
        {
            id: "work-items.relationship.added",
            name: "When related work is added to a demand",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.relationship.removed",
            name: "When related work is removed from a demand",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.created",
            name: "When a demand is created",
            trigger: "property",
            group: "work-items",
        },
        {
            id: "work-items.updated",
            name: "When a demand is updated",
            trigger: "property",
            group: "work-items",
        },
        {
            id: "work-items.assigned",
            name: "When a demand is assigned",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.started",
            name: "When a demand is started",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.blocked",
            name: "When a demand is blocked",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.unblocked",
            name: "When a demand is unblocked",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.resolved",
            name: "When a demand is resolved",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.reopened",
            name: "When a demand is re-opened",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.closed",
            name: "When a demand is closed",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.cancelled",
            name: "When a demand is cancelled",
            trigger: "event",
            group: "work-items",
        },
        {
            id: "work-items.message.added",
            name: "When a message is added to demand",
            trigger: "event",
            group: "work-items",
        },
        /// assurance
        {
            id: "assessments.automation.action",
            name: "When an automation is run",
            trigger: "event",
            group: "assessments",
        },
        {
            id: "assessments.stateflow",
            name: "When an workflow is triggered",
            trigger: "workflow",
            group: "assessments",
        },
        {
            id: "assessments.created",
            name: "When an assessment is created",
            trigger: "property",
            group: "assessments",
        },
        {
            id: "assessments.updated",
            name: "When an assessment is updated",
            trigger: "property",
            group: "assessments",
        },
        {
            id: "assessments.draft",
            name: "When a triage is completed",
            trigger: "event",
            group: "assessments",
        },
        {
            id: "assessments.started",
            name: "When an assessment is started",
            trigger: "event",
            group: "assessments",
        },
        {
            id: "assessments.remediation",
            name: "When an assessment moves to remediation",
            trigger: "event",
            group: "assessments",
        },
        {
            id: "assessments.reviewing",
            name: "When an assessment moves to risk review",
            trigger: "event",
            group: "assessments",
        },
        {
            id: "assessments.closed",
            name: "When an assessment is closed",
            trigger: "event",
            group: "assessments",
        },
        {
            id: "assessments.cancelled",
            name: "When an assessment is cancelled",
            trigger: "event",
            group: "assessments",
        },
        {
            id: "assessments.message.added",
            name: "When a message is added to assessment",
            trigger: "event",
            group: "assessments",
        },
        /// risks
        {
            id: "risks.automation.action",
            name: "When an automation is run",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.relationship.added",
            name: "When a relationship is added",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.relationship.removed",
            name: "When a relationship is removed",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.document.uploaded",
            name: "When a document is uploaded",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.stateflow",
            name: "When a workflow is triggered",
            trigger: "workflow",
            group: "risks",
        },
        {
            id: "risks.created",
            name: "When a risk is created",
            trigger: "property",
            group: "risks",
        },
        {
            id: "risks.updated",
            name: "When a risk is updated",
            trigger: "property",
            group: "risks",
        },
        {
            id: "risks.assigned",
            name: "When a risk is assigned",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.submitted",
            name: "When a risk is submitted",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.monitoring",
            name: "When a risk is monitored",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.transferred",
            name: "When a risk is transferred",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.review",
            name: "When a risk is reviewed",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.closed",
            name: "When a risk is closed",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.cancelled",
            name: "When a risk is cancelled",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.lifecycle",
            name: "When a risk lifecycle is created",
            trigger: "event",
            group: "risks",
        },
        {
            id: "risks.message.added",
            name: "When a message is added to risk",
            trigger: "event",
            group: "risks",
        },
    ],
};

export const getEventsForContext = (context, triggerId) => {
    return WorkflowConstants.events.filter((t) => t.id === triggerId);
};

export const getEventDisplayName = (eventId) => {
    const event = WorkflowConstants.events.find((e) => e.id === eventId);
    return event ? event.name : eventId;
};

export const getEventIconName = (eventId) => {
    const event = WorkflowConstants.events.find((e) => e.id === eventId);
    if (event) {
        const trigger = WorkflowConstants.triggers.find((t) => t.id === event.trigger);
        if (trigger) {
            return trigger.icon;
        }
    }

    return "LightningBolt";
};

export const getActivityDisplayName = (action) => {
    const activity = WorkflowConstants.activities.find((a) => a.id === action.uniqueId);
    return activity ? activity.name : action.name;
};

export const getActivityIconName = (action) => {
    const activity = WorkflowConstants.activities.find((a) => a.id === action.uniqueId);
    return activity ? activity.icon : "Rocket";
};

export const getActivityScopeLabel = (scope) => {
    switch (scope.type) {
        case "module":
            const s = WorkflowConstants.scopes.find((s) => s.module === scope.id);
            return `Defined in ${s.name.toLowerCase()} module`;
        case "trigger":
            return `Defined in ${scope.label} trigger of workflow`;
        default:
            const et = WorkflowConstants.entityTypes.find((s) => s.id === scope.type);
            return `Defined in ${et.name.toLowerCase()} ${scope.label}`;
    }
    return scope.label;
};

export const getScopeFromEventGroup = (group) => {
    return WorkflowConstants.scopes.find((s) => s.group === group);
};

export const getEntityTypeFromModule = (moduleId) => {
    return WorkflowConstants.scopes.find((s) => s.module === moduleId)?.id;
};
