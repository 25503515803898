import { observable, action, computed, flow } from "mobx";

import { RiskService } from "@api/risks";
import { RiskStore } from "@modules/risks/stores/RiskStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class RiskNewFormStore {
    private riskService: RiskService;
    public parentStore: RiskStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        this.formOptions = {
            pageTitle: options.pageTitle || `New ${options.risk.type.name}`,
            pageDescription: options.pageDescription || options.risk.type.summary,
            section: "Risk",
        };

        const risk = {
            title: null,
            trigger: "Manual",
            asset: null,
            stakeholder: null,
            scenario: null,
            lifecycle: { businessContact: null, technicalContact: null },
            ...options.risk,
            type: { id: options.risk.type.id, name: options.risk.type.name },
            registry: { id: options.risk.type.defaultRegistryId },
            review: { treatments: [] },
            documents: [],
        };

        const assetStore = this.parentStore.rootStore.assetStore;
        if (options.assetId) {
            risk.asset = yield assetStore.assetService.getAsset(options.assetId);
        }
        if (options.stakeholderId) {
            risk.stakeholder = yield assetStore.assetService.getAsset(options.stakeholderId);
        }

        let scenario = null;
        if (risk.advisories && risk.advisories.length === 1) {
            scenario = risk.advisories[0].scenario;
        } else if (options.risk && options.risk.scenario) {
            scenario = options.risk.scenario;
        }

        if (scenario) {
            scenario = yield assetStore.assetService.getAsset(scenario.id);
            risk.scenario = scenario;

            risk.title = scenario.name;
            risk.description = scenario.summary;

            if (scenario.extension) {
                if (
                    scenario.extension.lSGrossScore &&
                    scenario.extension.lSGrossLikelihood &&
                    scenario.extension.lSGrossImpact
                ) {
                    risk.review.grossScore = {
                        score: scenario.extension.lSGrossScore,
                        likelihood: scenario.extension.lSGrossLikelihood,
                        impact: scenario.extension.lSGrossImpact,
                        description: scenario.extension.lSGrossDescription || "",
                    };
                }

                if (
                    scenario.extension.lSNetScore &&
                    scenario.extension.lSNetLikelihood &&
                    scenario.extension.lSNetImpact
                ) {
                    risk.review.netScore = {
                        score: scenario.extension.lSNetScore,
                        likelihood: scenario.extension.lSNetLikelihood,
                        impact: scenario.extension.lSNetImpact,
                        description: scenario.extension.lSNetDescription || "",
                    };
                }

                if (scenario.extension.lSTreatmentPlan && scenario.extension.lSTreatmentPlan.length > 0) {
                    const treatmentIds = scenario.extension.lSTreatmentPlan.map((t) => t.id);
                    const treatments = yield assetStore.assetService
                        .searchAssets({
                            query: {
                                id: treatmentIds,
                            },
                        })
                        .then((result) =>
                            result.items.map((t) => ({
                                type: t.extension.treatmentType,
                                description: t.extension.treatmentAction,
                            }))
                        );
                    risk.review.treatments = treatments.filter((t) => t.type && t.description);
                }
            }
        }

        if (risk.assessment && risk.assessment.id) {
            this.formOptions.disableTrigger = true;
            risk.trigger = "Assessment";
        }

        this.formData = risk;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.title &&
            this.formData.type &&
            this.formData.trigger &&
            this.formData.type.id &&
            this.formData.registry &&
            this.formData.registry.id &&
            this.formData.asset &&
            this.formData.asset.id &&
            this.formData.stakeholder &&
            this.formData.stakeholder.id &&
            this.formData.lifecycle &&
            this.formData.lifecycle.businessContact &&
            this.formData.lifecycle.businessContact.id &&
            this.formData.lifecycle.technicalContact &&
            this.formData.lifecycle.technicalContact.id &&
            this.formData.description &&
            this.formData.context
        );
    }
}
