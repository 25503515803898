import { observable, action, computed, flow } from "mobx";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export class ControlReviewFormStore {
    public controlService: ControlService;
    public parentStore: ControlStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        this.formOptions = {
            ...options,
            pageType: options.pageType || `Test Control`,
        };

        const review = {
            ...options.review,
            threatSurface: "Wellcome General",
            groups: [
                {
                    capabilityLevel: { id: 1, name: "Insider Attack" },
                    measures: [
                        {
                            id: 1,
                            title: "Fill in the assets list template with all critical assets",
                            description: "Critical asset list must contain the following fields. XYZ",
                            capabilityLevel: "Organised Crime",
                            value: null,
                            progress: 0,
                        },
                        {
                            id: 2,
                            title: "Review asset list  every 12 months",
                            capabilityLevel: "Organised Crime",
                            value: null,
                            progress: 0,
                        },
                    ],
                },
                {
                    capabilityLevel: { id: 2, name: "Organised Crime" },
                    measures: [
                        {
                            id: 3,
                            title: "Ensure there is an automated asset discovery tool to the environment",
                            capabilityLevel: "Organised Crime",
                            value: null,
                            progress: 0,
                        },
                    ],
                },
            ],
        };
        this.formData = review;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });

        this.hide({}); //TODO : just for demo should be done in lifecycle store
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData &&
            this.formData.title &&
            this.formData.portfolio &&
            this.formData.portfolio.id &&
            this.formData.product &&
            this.formData.product.id &&
            this.formData.priority &&
            this.formData.dueDate &&
            this.formData.source &&
            this.formData.requestedBy &&
            this.formData.requestedBy.id
        );
    }
}
