import { observable, flow } from "mobx";

import { AssetService } from "@api/assets";
import { AssetStore } from "./AssetStore";

import { PaginationStore } from "@modules/base/PaginationStore";

export class AssetCatalogueStore {
    public parentStore: AssetStore;
    public assetService: AssetService;

    @observable public loading: boolean = false;
    @observable public types: any[] = [];
    @observable public query: any = {};
    @observable public pagination: PaginationStore;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Inventory Management",
            key: "inventory",
            onClick: () => this._goToUrl(`/inventory/browse`),
        },
        { text: "Inventory Catalogue", key: "inventory-catalogue", isCurrentItem: true },
    ];

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
        this.pagination = new PaginationStore();
        this.pagination.itemsPerPage = 100;
    }

    public loadAssetTypes = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: {} }, options);
        this.query = o.query;
        this.loading = true;
        this.error = null;

        try {
            const result = yield this.assetService.searchAssetTypes({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });
            this.types = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
            return result;
        } catch (e) {
            this.error = e;
            console.error(e);
            return e;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
